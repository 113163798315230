/**
 * Sentry DSN.
 *
 * Leave empty to disable Sentry.
 */
export const SENTRY_DSN = 'https://74245313f86c1ebe3683306585762fad@sentry.naukosfera.com/4'

/**
 * Release version of Sentry.
 */
export const SENTRY_RELEASE = '9341408bf51505c80c928d522be2d8c901fde1f5'
