import * as React from 'react'
import {
  Admonition,
  AltText,
  Caption,
  Transforms as CnxTransforms,
  Commentary,
  CrossReference,
  DocumentReference,
  Figure,
  Footnote,
  Glossary,
  Link,
  ListItem,
  Media,
  MediaData,
  Preformat,
  Problem,
  Proof,
  Quotation,
  Rule,
  RuleExample,
  Section,
  Solution,
  Statement,
  Table,
} from 'cnx-designer'
import { List } from 'slate-lists'
import { Editor, Node, Path, Range, Transforms } from 'slate'
import { SuggestionsEditor } from 'slate-suggestions'
import { FileDescription } from '../../../../../../../api/storage'
import Modal from '../../../../../../../components/Modal'
import Button from '../../../../../../../components/ui/Button'
import Icon from '../../../../../../../components/ui/Icon'
import { Highlight } from '../../../../../../../screens/app/Draft/plugins/Highlights'
import Tooltip from '../../../../../../../components/ui/Tooltip'
import AssetList from '../../../../../../../containers/AssetList'
import XrefTargetSelector, { XrefTargetData } from '../../../../../../../containers/XrefTargetSelector'
import ToolGroup from '../ToolGroup'
import { OnToggle } from '../../index'
import { CurrentDraftContext } from '../../../../../../../screens/app/Draft'
import { MediaText } from '../../../../../../../screens/app/Draft/plugins/MediaText'
import { getNodeAtSelection, validateParents, Validator } from '../../../../../../../screens/app/Draft/utils'
import { SourceElement } from '../../../../../../../screens/app/Draft/plugins/SourceElements'
import { useCurrentSelection } from '../../../../../../../screens/app/Draft/withCurrentSelection'
import scrollToTarget from '../../../../../../../helpers/scrollToTarget'

// List of validators for direct parents above current selection.
// Paragraphs are ignored during this process.
const VALID_PARENTS_LINK: Validator[] = [
  Editor.isEditor,
  Section.isSection,
  Admonition.isAdmonition,
  Problem.isProblem,
  Solution.isSolution,
  Commentary.isCommentary,
  Rule.isRule,
  Statement.isStatement,
  Proof.isProof,
  RuleExample.isRuleExample,
  ListItem.isListItem,
  Caption.isCaption,
  Footnote.isFootnote,
  Highlight.isHighlight,
]
const VALID_PARENTS_CODE: Validator[] = [
  Editor.isEditor,
  Section.isSection,
  Admonition.isAdmonition,
  Problem.isProblem,
  Solution.isSolution,
  Commentary.isCommentary,
  Highlight.isHighlight,
]
const VALID_PARENTS_ADMONITION: Validator[] = [
  Editor.isEditor,
  Section.isSection,
  Admonition.isAdmonition,
  Highlight.isHighlight,
]
const VALID_PARENTS_EXERCISE = [
  Editor.isEditor,
  Section.isSection,
  Highlight.isHighlight,
  Admonition.isAdmonition,
]
const VALID_PARENTS_FIGURE = [
  Editor.isEditor,
  Admonition.isAdmonition,
  Section.isSection,
  Problem.isProblem,
  Solution.isSolution,
  ListItem.isListItem,
  Highlight.isHighlight,
]
const VALID_PARENTS_QUOTATION = [
  Editor.isEditor,
  Section.isSection,
  Admonition.isAdmonition,
  Problem.isProblem,
  Solution.isSolution,
  Quotation.isQuotation,
  Highlight.isHighlight,
]
const VALID_PARENTS_TITLE = [
  Editor.isEditor,
  Section.isSection,
  Admonition.isAdmonition,
  Quotation.isQuotation,
  Rule.isRule,
  Statement.isStatement,
  Proof.isProof,
  RuleExample.isRuleExample,
  Figure.isFigure,
  Media.isMedia,
  MediaData.isMediaData,
  AltText.isAltText,
  Caption.isCaption,
  Highlight.isHighlight,
]
const VALID_PARENTS_PREFORMAT = [
  Editor.isEditor,
  Section.isSection,
  Admonition.isAdmonition,
  Problem.isProblem,
  Solution.isSolution,
  Quotation.isQuotation,
  Preformat.isPreformat,
  ListItem.isListItem,
  Highlight.isHighlight,
]
const VALID_PARENTS_SOURCE_ELEMENT = [
  Editor.isEditor,
  Section.isSection,
  Admonition.isAdmonition,
  Problem.isProblem,
  Solution.isSolution,
  Quotation.isQuotation,
  Highlight.isHighlight,
]
const VALID_PARENTS_RULE = [
  Editor.isEditor,
  Section.isSection,
  Highlight.isHighlight,
]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const VALID_PARENTS_EQUATION = [
  Admonition.isAdmonition,
  Commentary.isCommentary,
  Editor.isEditor,
  Problem.isProblem,
  Proof.isProof,
  Rule.isRule,
  RuleExample.isRuleExample,
  Section.isSection,
  Solution.isSolution,
  Statement.isStatement,
]

// List of invalid validators for direct parents above current selection.
// Paragraphs are ignored during this process.
const INVALID_PARENTS_FOOTNOTE = [
  Media.isMedia,
  AltText.isAltText,
  MediaText.isMediaText,
  Preformat.isPreformat,
  Rule.isRule,
  Statement.isStatement,
  Proof.isProof,
  RuleExample.isRuleExample,
  Highlight.isHighlight,
]

interface InsertToolsProps {
  toggleState: boolean
  editor: Editor
  onToggle: OnToggle
}

const InsertTools = ({ editor, ...props }: InsertToolsProps) => {
  const selection = useCurrentSelection()
  const mediaModal = React.useRef<Modal>(null)
  const xrefModal = React.useRef<Modal>(null)
  const currentDraftContext = React.useContext(CurrentDraftContext)
  const selectionIsInsideGlossary = React.useMemo(
    () => Editor.above(editor, { at: selection, match: Glossary.isGlossary }),
    [editor, selection])

  const renderMediaModal = () => (
    <AssetList onSelect={insertMedia} />
  )

  const renderXrefModal = () => (
    <XrefTargetSelector onSelect={handleXrefTarget} />
  )

  const toggleAdmonition = () => {
    // TODO: clicking insert admonition should expand a menu where the user can
    // choose which kind of admonition to insert.
    const admonition = getNodeAtSelection(editor, Admonition.isAdmonition, selection)
    if (admonition) {
      Transforms.unwrapNodes(editor, { at: admonition[1] })
      return
    }

    Transforms.wrapNodes(
      editor,
      { type: 'admonition', children: [] },
      {
        split: selection && Range.isExpanded(selection),
        match: n => Editor.isBlock(editor, n) && !Editor.isEditor(n) && !Section.isSection(n),
        mode: 'highest',
      },
    )
  }

  const insertMedia = (asset: FileDescription) => {
    mediaModal.current!.close()
    const isAudio = /^audio/.test(asset.mime)
    const isVideo = /^video/.test(asset.mime)
    let type: 'media_image' | 'media_audio' | 'media_video' = 'media_image'
    if (isAudio) {
      type = 'media_audio'
    } else if (isVideo) {
      type = 'media_video'
    }
    CnxTransforms.insertFigure(
      editor,
      { type, src: asset.name, intendedUse: 'all' },
    )
  }

  const insertTitle = () => CnxTransforms.wrapSectionTitle(editor)

  const insertCode = () => {
    const fragment = selection && Range.isExpanded(selection) ? Editor.fragment(editor, selection) : null
    const text = fragment ? Node.string({ children: fragment }) : ''
    Transforms.insertNodes(
      editor,
      { type: 'code', placement: 'block', children: [{ text }] })
  }

  const toggleQuotation = () => {
    const quotation = getNodeAtSelection(editor, Quotation.isQuotation, selection)
    if (quotation) {
      Transforms.unwrapNodes(editor, { at: quotation[1] })
      return
    }

    const list = getNodeAtSelection(editor, List.isList, selection)

    Transforms.wrapNodes(
      editor,
      { type: 'quotation', children: [] },
      {
        split: selection && Range.isExpanded(selection),
        at: list ? list[1] : selection,
      },
    )
  }

  const handleXrefTarget = (data: XrefTargetData) => {
    xrefModal.current!.close()
    let node: Link | DocumentReference | CrossReference

    switch (data.type) {
    case 'link':
      node = { type: 'link', url: data.url, children: [{ text: data.text }] } as Link
      break

    case 'docref':
      node = {
        type: 'docref',
        document: data.source.id,
        children: [{ text: '' }],
      } as DocumentReference
      break

    case 'xref':
      node = {
        type: 'xref',
        target: data.target.id,
        document: data.source?.id,
        children: [{ text: '' }],
      } as CrossReference
      break

    default:
      console.error(`Unknown type in ${data}`)
      return
    }

    if (selection) {
      Transforms.insertNodes(editor, node, { at: selection })
    }
  }

  const insertPreformat = () => {
    const preformat = getNodeAtSelection(editor, Preformat.isPreformat, selection)
    if (preformat) {
      Transforms.setNodes(editor, { type: 'paragraph' }, { at: preformat[1] })
      return
    }
    const fragment = selection && Range.isExpanded(selection) ? Editor.fragment(editor, selection) : null
    const text = fragment ? Node.string({ children: fragment }) : ''
    Transforms.insertNodes(editor, { type: 'preformat', children: [{ text }] })
  }

  const insertSourceElement = () => {
    const source: SourceElement = {
      type: 'code',
      placement: 'block',
      language: 'cnxml-fragment',
      children: [{ text: ' ' }],
    }
    if (selection && Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, source)
      Transforms.move(editor, { distance: 1, reverse: true })
    } else {
      Transforms.wrapNodes(editor, source, { split: true })
    }
  }

  const insertFootnote = () => {
    const footnote = Editor.nodes(editor, { at: selection, match: Footnote.isFootnote })
      .next().value as [Footnote, Path] | undefined
    if (footnote) {
      Transforms.unwrapNodes(editor, { at: footnote[1], match: Footnote.isFootnote })
      return
    }
    if (selection && !Range.isCollapsed(selection)) {
      Transforms.wrapNodes(editor, { type: 'footnote', children: [] }, { split: true })
    } else {
      Transforms.insertNodes(editor, { type: 'footnote', children: [{ text: ' ' }] })
      Transforms.move(editor, { distance: 1, reverse: true })
    }
  }

  const insertRule = () => {
    const rule = getNodeAtSelection(editor, Rule.isRule, selection)
    if (rule) {
      Transforms.unwrapNodes(editor, { at: rule[1] })
      return
    }
    CnxTransforms.insertRule(editor)
  }

  const insertDefinition = () => {
    CnxTransforms.addGlossaryDefinition(editor, { select: true })
    window.requestAnimationFrame(() => scrollToTarget(".definition:last-of-type"))
  }

  return (
    <ToolGroup
      title="editor-tools-insert-tools-title"
      toggleState={props.toggleState}
      className="toolbox__insert-buttons"
      onToggle={() => props.onToggle('insertTools')}
    >
      {
        !selectionIsInsideGlossary
          ? <>
            <Tooltip l10nId="editor-tools-insert-link">
              <Button
                id="editor-tools-insert-link"
                clickHandler={() => xrefModal.current!.open()}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_LINK, selection)}
                dataId="link"
              >
                <Icon size="small" name="www" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-code">
              <Button
                id="editor-tools-insert-code"
                clickHandler={insertCode}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_CODE, selection)}
                dataId="code"
              >
                <Icon size="small" name="code" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-admonition">
              <Button
                id="editor-tools-insert-admonition"
                clickHandler={toggleAdmonition}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_ADMONITION, selection)}
                dataId="admonition"
              >
                <Icon size="small" name="sticky-note" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-exercise">
              <Button
                id="editor-tools-insert-exercise"
                clickHandler={() => CnxTransforms.insertExercise(editor)}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_EXERCISE, selection)}
                dataId="exercise"
              >
                <Icon size="small" name="flask" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-media">
              <Button
                id="editor-tools-insert-media"
                clickHandler={() => mediaModal.current!.open()}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_FIGURE, selection)}
                dataId="media"
              >
                <Icon size="small" name="image" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-quotation">
              <Button
                id="editor-tools-insert-quotation"
                clickHandler={toggleQuotation}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_QUOTATION, selection)}
                dataId="quotation"
              >
                <Icon size="small" name="quote" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-title">
              <Button
                id="editor-tools-insert-title"
                clickHandler={insertTitle}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_TITLE, selection, false)}
                dataId="title"
              >
                <Icon size="small" name="heading" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-preformat">
              <Button
                id="editor-tools-insert-preformat"
                clickHandler={insertPreformat}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_PREFORMAT, selection)}
                dataId="preformat"
              >
                <Icon size="small" name="preformat" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-source">
              <Button
                id="editor-tools-insert-source"
                clickHandler={insertSourceElement}
                className="toolbox__button--insert"
                isDisabled={
                  currentDraftContext.state.currentDraftPermissions.includes('propose-changes') ||
                  !validateParents(editor, VALID_PARENTS_SOURCE_ELEMENT, selection)
                }
                dataId="source"
              >
                <Icon size="small" name="file-code" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-footnote">
              <Button
                id="editor-tools-insert-footnote"
                clickHandler={insertFootnote}
                className="toolbox__button--insert"
                isDisabled={validateParents(editor, INVALID_PARENTS_FOOTNOTE, selection)}
                dataId="footnote"
              >
                <Icon size="small" name="footnote" />
              </Button>
            </Tooltip>
            <Tooltip l10nId="editor-tools-insert-rule">
              <Button
                id="editor-tools-insert-rule"
                clickHandler={insertRule}
                className="toolbox__button--insert"
                isDisabled={!validateParents(editor, VALID_PARENTS_RULE, selection)}
                dataId="rule"
              >
                <Icon size="small" name="rule" />
              </Button>
            </Tooltip>
          </>
          : null
      }
      <Tooltip l10nId="editor-tools-definition-insert-definition">
        <Button
          id="editor-tools-definition-insert-definition"
          clickHandler={insertDefinition}
          className="toolbox__button--insert"
          dataId="definition"
        >
          <Icon size="small" name="plus" />
        </Button>
      </Tooltip>
      <Modal
        ref={mediaModal}
        content={renderMediaModal}
      />
      <Modal
        ref={xrefModal}
        content={renderXrefModal}
        showCloseButton={false}
      />
    </ToolGroup>
  )
}

export default InsertTools
